<template>
  <div>
    <template v-if="value.length == 1">
      <span v-html="listText()"></span>
    </template>
    <template v-else-if="value.length > 1">
      <ul class="pl-0">
        <li
          v-for="(img, index) in value"
          v-bind:key="index"
          v-bind:class="[
            'd-block',
            singleValidation(index) ? 'text-dark' : 'text-danger',
          ]"
          v-html="listText(index + 1)"
        ></li>
      </ul>
    </template>
  </div>
</template>
<script>
export default {
  name: "ImageAlt",
  props: {
    value: {
      default: () => [],
    },
    focusKeyword: {
      default: null,
    },
  },
  methods: {
    listText(index = false) {
      let result = "Görsel Alt etiketi <b>Anahtar Kelime</b>yi içermeli.";
      if (index) result = `<b>${index}</b>. ${result}`;

      return result;
    },
    singleValidation(index = 0) {
      let imageAlt = this.value[index].metaAlt;
      imageAlt = imageAlt ? imageAlt.toLocaleLowerCase("tr-TR") : null;

      return imageAlt ? imageAlt.includes(this.getFocusKeyword) : false;
    },
  },
  computed: {
    getValidation() {
      return (
        this.value.length ==
        this.value.filter((item) => {
          const imageAlt = item.metaAlt
            ? item.metaAlt.toLocaleLowerCase("tr-TR")
            : null;

          return imageAlt && imageAlt.includes(this.getFocusKeyword);
        }).length
      );
    },
    getFocusKeyword() {
      return this.focusKeyword
        ? this.focusKeyword.toLocaleLowerCase("tr-TR")
        : null;
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>